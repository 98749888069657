import React from "react";
import { Typography, Box, Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Brewing = () => {
  return (
    <Container>
      <Helmet>
        <title>Black Medicine's Brew Method</title>
        <meta
          name="description"
          content="Our proprietary brew method is central to our success."
        />
      </Helmet>

      <Box
        sx={{ maxWidth: "lg" }}
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container>
          <Grid item xs={12} sx={{ my: 3 }}>
            <Typography variant="h1" align="center">
              Black Medicine's Brew Method
            </Typography>
          </Grid>

          <Grid item sx={{ my: 3 }}>
            <Typography variant="h4">
              Our proprietary brew method is central to our success.
            </Typography>
          </Grid>

          <Grid item sx={{ marginBottom: 2 }}>
            Developed over 15 years ago, Black Medicine's proprietary method for
            making coffee concentrate gets the most out of your roast.
            Controlling all important parameters, we produce high quality
            concentrate with the consistent character of customer's roasted
            coffee beans.
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Brewing;
