import React from "react";
import { Typography, Box, Grid, Container, List, ListItem } from "@mui/material";
import { Helmet } from "react-helmet-async";

import sigAlex from "../../static/images/signature_alex.png";

const About = () => {
  return (
    <Container>
      <Helmet>
        <title>About Black Medicine</title>
        <meta
          name="description"
          content="California's craft coffee co-packer and center for beverage innnovation"
        />
      </Helmet>

      <Box
        sx={{ maxWidth: "lg" }}
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container>
          <Grid item xs={12} sx={{ my: 3 }}>
            <Typography variant="h1" align="center">
              About Us
            </Typography>
          </Grid>

          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
              Coffee Canning / Co-Packing
            </Typography>
            <Typography pb={3}>We take your beautifully roasted coffee beans and brew them into a high TDS concentrate.   We then blend this concentrate according to your recipe to create a delicious coffee drink that reflects your brand's unique flavor and design.  
            </Typography>

            <List sx={{ }}>
              <ListItem>Lattes</ListItem>
              <ListItem>Mochas</ListItem>
              <ListItem>Oat milk lattes</ListItem>
              <ListItem>Teas</ListItem>
              <ListItem>Sparkling beverages</ListItem>
              <ListItem>And more...</ListItem>
            </List>
          </Grid>

          
          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Pasteurization
            </Typography>
            <Typography pb={3}>Products containing milk and/or sugars typically require pasteurization to achieve a workable shelf life.  If your product requires pasteurization, we’ll run it through our pasteurization chamber after canning.
            </Typography>
          </Grid>

          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Cold Coffee Intensified 
            </Typography>
            <Typography pb={2}>If your product is an iced coffee, you should know that the proprietary hot brewing process used at Mountjoy Sparkling was developed and refined for two years in the back room of the small Oakland coffee bar of Black Medicine's founder, Chris Cooper. Chris' innovation is the expression of his passion to make great coffee that anybody can enjoy at any time, in any place. 
            </Typography>
            <Typography pb={2}>At Mountjoy we use Chris' trade secret process - brewing hot in an oxygen-free environment, then cold pressing to extract the maximum flavor from each bean. This process works especially well for coffee that is meant to be served cold, especially if the desired flavor profile developed is bold, robust, and very intense. Packaging in cans that are always kept refrigerated ensures your custom brew yields the best possible iced coffee. 
            </Typography>
          </Grid>

          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Waters and Sparkling Drinks 
            </Typography>
            <Typography pb={2}>Our expertise extends to the creation and production of flavored waters and sparkling drinks, which can be produced in plastic bottles or aluminum cans. With our Nanotonic family of functional ingredients, we can produce packaged drinks using capabilities that few other beverage facilities have.
            </Typography>
           
          </Grid>

          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Hemp extract and CBD 
            </Typography>
            <Typography pb={2}>Mountjoy Enterprises is licensed by the state of California to incorporate CBD-rich hemp extract, into beverages.  Our NanoTonic family of water-soluble formulations offers a custom-tailored solution for each beverage's unique profile and customer needs.
            </Typography>
           
          </Grid>

          
          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Bottling Too 
            </Typography>
            <Typography pb={2}>Our production line can accommodate glass and plastic bottles as well as cans.  We also have custom equipment to manage thicker beverages, such as shakes.  
            </Typography>
           
          </Grid>

          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Case Packing 
            </Typography>
            <Typography pb={2}>Whether your case pack is a tray, shrink-wrapped, or a custom-printed box, we can accommodate your needs. 
            </Typography>
           
          </Grid>

          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Shrink Sleeves or Labels 
            </Typography>
            <Typography pb={2}>Whether your packaging design calls for a shrink sleeve or a pressure-sensitive label, we can accommodate your needs.  Labels offer flexibility for smaller runs, as well as special effects such as metallic accents whereas shrink sleeves offer greater coverage and economy.  The choice is yours.
            </Typography>
           
          </Grid>

          <Grid item xs={12} >
            <Typography variant="h2" align="center" pb={3}>
            Our Mission
            </Typography>
            <Typography pb={2}>Our mission is to provide fertile ground for the growth of your brand.  Combining flexibility in minimums, extensive formulation capabilities, experience with special ingredients and beverage industry know-how, Mountjoy is northern California's center for beverage innovation.
            </Typography>
           
          </Grid>

          <Grid item xs={12} >
            <Typography pb={2}>We look forward to working with you!
            </Typography>
            <Box pt={2} maxWidth={300}>
            <a href="https://www.mountjoysparkling.com" target="_blank"><img src={sigAlex} alt="Signature" /></a>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </Container>
  );
};

export default About;
