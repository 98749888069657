import React from "react";
import { Typography, Box, Container, Grid } from "@mui/material";

import griffenCatching from "../../static/images/griffen_catching.jpg";
import bruceBrewing from "../../static/images/bruce_brewing.jpg";
import nitrogenDosing from "../../static/images/nitrogen_dosing_2428.jpg";
import pouringCoffee from "../../static/images/scooping_beans_2428.jpg";
import katieLab from "../../static/images/katie_lab_2428.jpg";
import pouringCoffeeBrewed from "../../static/images/pouring_coffee_brewed_2428.jpg";

import { Helmet } from "react-helmet-async";

const Gallery = ({ user }) => {
  return (
    <div>
      <Helmet>
        <title>Gallery of Images - Black Medicine Canning/Co-Packing</title>
        <meta name="description" content="Photo gallery from our shop" />
      </Helmet>

      <Box px={{ xs: 3, sm: 10 }} py={{ xs: 5, sm: 10 }}>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            {/* Setting up the Fluid Grid system */}
            <Grid container spacing={4}>
              <Grid item xs={12} alignItems="center" pb={10}>
                <Typography variant="h1" align="center">
                  Gallery
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems="center" pb={3}>
                <Typography variant="h2" align="center">
                  We hope you enjoy these shots from our shop.
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <img
                  className="rounded-md"
                  src={pouringCoffee}
                  alt="Roast"
                  object-fit="cover"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <img className="rounded-md" src={katieLab} alt="Lab" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <img
                  className="rounded-md"
                  src={pouringCoffeeBrewed}
                  alt="Roast"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <img className="rounded-md" src={nitrogenDosing} alt="Lab" />
              </Grid>

              <Grid item xs={12} lg={6}>
                <img className="rounded-md" src={bruceBrewing} alt="Lab" />
              </Grid>
              <Grid item xs={12} lg={6}>
                <img className="rounded-md" src={griffenCatching} alt="Lab" />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Gallery;
