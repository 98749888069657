import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Paper,
  Typography,
  Box,
  Button,
  Container,
  Grid,
} from "@mui/material";

import nitrogenDosing from "../../static/images/nitrogen_dosing_2428.jpg";
import pouringCoffee from "../../static/images/scooping_beans_2428.jpg";
import katieLab from "../../static/images/katie_lab_2428.jpg";
import pouringCoffeeBrewed from "../../static/images/pouring_coffee_brewed_2428.jpg";

import equatorLogo from "../../static/images/equator_logo.png";
import endorffeineLogo from "../../static/images/endorffeine_logo.png";
import addictiveLogo from "../../static/images/addictive_logo.png";
import cultLogo from "../../static/images/cult_logo.png";
import ritualLogo from "../../static/images/ritual_logo.png";

import { Helmet } from "react-helmet-async";

import "../../style/SlideShow.css";

import { useTheme } from "@mui/material/styles";

const Home = ({ user }) => {
  return (
    <div>
      <Helmet>
        <title>Black Medicine Canning/Co-Packing</title>
        <meta
          name="description"
          content="Northern California's leading small batch canning co."
        />
      </Helmet>

      <Box px={{ xs: 3, sm: 10 }} py={{ xs: 5, sm: 10 }}>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            {/* Setting up the Fluid Grid system */}
            <Grid container spacing={2}>
              <Grid item xs={12} alignItems="center" pb={10}>
                <Box px={{ xs: 3, sm: 8 }} py={{ xs: 5, sm: 8 }}>
                  <Typography variant="h1" align="center">
                    BLACK MEDICINE CANNING/CO-PACKING
                  </Typography>
                </Box>
                <Typography variant="h2" align="center">
                  Northern California's leading small batch canning co.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <a href="/">
                  <Typography variant="h4">Your Roast</Typography>
                  <img className="rounded-md" src={pouringCoffee} alt="Roast" />
                </a>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Laboratory Services</Typography>
                <img className="rounded-md" src={katieLab} alt="Lab" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Small Batch Brewing</Typography>
                <img
                  className="rounded-md"
                  src={pouringCoffeeBrewed}
                  alt="Roast"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Nitrogen Dosing</Typography>
                <img className="rounded-md" src={nitrogenDosing} alt="Lab" />
              </Grid>
              <Grid item xs={12} md={6}>
                <a href="/">
                  <Typography variant="h4">High TDS Concentrate</Typography>
                </a>
              </Grid>
              <Grid item xs={12} md={6}>
                <a href="/">
                  <Typography variant="h4">Sleeving</Typography>
                </a>
              </Grid>
              <Grid item xs={12} md={6}>
                <a href="/">
                  <Typography variant="h4">Hands On Quality Control</Typography>
                </a>
              </Grid>
              <Grid item xs={12} md={6} pb={2}>
                <a href="/">
                  <Typography variant="h4">High Volume Canning</Typography>
                </a>
              </Grid>
            </Grid>
          </Box>
          <Box px={{ xs: 3, sm: 1 }} py={{ xs: 5, sm: 6 }}>
            <Grid container spacing={3} pt={4}>
              <Grid
                item
                xs={6}
                md={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={equatorLogo} alt="Equator Coffees" />
              </Grid>

              <Grid
                item
                xs={6}
                md={2.4}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <img src={endorffeineLogo} alt="Endorffeine" />
              </Grid>

              <Grid
                item
                md={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box align="center">
                  <img
                    align="center"
                    src={addictiveLogo}
                    alt="Addictive Coffees"
                  />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                md={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box sx={{ margin: "auto" }}>
                  <img src={cultLogo} alt="Cult Beverage" width="125" />
                </Box>
              </Grid>

              <Grid
                item
                xs={6}
                md={2.4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img src={ritualLogo} alt="Ritual Coffee Roasters" />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
