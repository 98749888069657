import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import ResponsiveFooter from "./components/Footer/ResponsiveFooter";
import ResponsiveAppBar from "./components/NavBar/ResponsiveAppBar";
import Copyright from "./components/Copyright/Copyright";
import Contact from "./components/Contact/Contact";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Craft from "./components/Craft/Craft";
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Terms/Terms";
import Gallery from "./components/Gallery/Gallery";
import Brewing from "./components/Brewing/Brewing";
import Account from "./components/Account/Account";
import NotFound from "./components/NotFound/NotFound";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
  CssBaseline,
} from "@mui/material";
import { teal, cyan, lightBlue, lightGreen } from "@mui/material/colors";
import { HelmetProvider } from "react-helmet-async";

// Special fonts.

import big_noodle_titling from "./static/fonts/big_noodle_titling.ttf";

//import theme from "./Theme.js";

let theme = createTheme({
  palette: {
    primary: {
      // main: "#75cece", // Header and footer backgrounds
      main: teal["200"],
    },
    secondary: {
      main: "#ffffff", // Body background
    },
    text: {
      primary: "#000000", // Color of primary text
      secondary: "#000000",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: lightBlue[50],
        },
        contained: {
          backgroundColor: lightGreen[50],
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        color: "yellow",
        backgroundColor: "orange",
        borderColor: "green",
      },
    },
  },

  typography: {
    // Base font
    fontFamily: "Montserrat",

    h1: {
      fontSize: "4rem",
    },
    h2: {
      fontSize: "2rem",
    },
    // Site title and Menu bar items are h6
    h6: {
      fontFamily: ["BigNoodleTitling"],
      fontSize: "2.0rem",
    },
  },

  button: {
    fontFamily: ["BigNoodleTitling"],
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [big_noodle_titling],
      },
    },
  },
});

//let theme2 = createTheme();

// Use Material ui to manage font size changes by screen width.
theme = responsiveFontSizes(theme);

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <HelmetProvider>
          <CssBaseline />
          <BrowserRouter>
            <div>
              <ResponsiveAppBar />

              <Routes>
                <Route path="/" element={<Home />} exact></Route>
                <Route path="/about" element={<About />} exact></Route>
                <Route path="/brewing" element={<Brewing />} exact></Route>
                <Route path="/craft" element={<Craft />}></Route>
                <Route path="/contact" element={<Contact />} exact></Route>
                <Route path="/gallery" element={<Gallery />} exact></Route>
                <Route path="/privacy" element={<Privacy />} exact></Route>
                <Route path="/terms" element={<Terms />} exact></Route>
                <Route path="*" element={<NotFound />} />
              </Routes>

              <ResponsiveFooter />
              <Copyright />
            </div>
          </BrowserRouter>
        </HelmetProvider>
      </ThemeProvider>
    );
  }
}

export default App;
