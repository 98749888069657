import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Privacy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Notice of privacy policy" />
      </Helmet>
      <Box px={{ xs: 3, sm: 10 }} py={{ xs: 5, sm: 10 }}>
        <Grid container>
          <Grid item sx={{ my: 3 }}>
            <Typography variant="h1" align="center">
              Privacy Policy
            </Typography>
          </Grid>

          <Grid item sx={{ my: 3 }}>
            <Typography>
              All customer data is kept strictly confidential. We do not sell,
              rent, or distribute our customers' information to anyone. We only
              use the information we collect about you to process orders and to
              provide support.
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h4">
              What information do we collect? How do we use it?
            </Typography>
          </Grid>

          <Grid item sx={{ marginBottom: 2 }}>
            When you order, we need to know your name, e-mail address, mailing
            address, credit card number, and expiration date. This allows us to
            process and fulfill your order and to notify you of your order
            status.
          </Grid>

          <Grid item>
            <Typography variant="h2">
              How does Mountjoy Enterprises protect customer information?
            </Typography>
          </Grid>

          <Grid item sx={{ marginBottom: 2 }}>
            We use a secure server for collecting personal and credit card
            information. The secure server layer (SSL) encrypts (scrambles) all
            of the information you enter before it is transmitted over the
            internet and sent to us. Furthermore, all of the customer data we
            collect is protected against unauthorized access.
          </Grid>

          <Grid item>
            <Typography variant="h2">
              Links Outside mountjoysparkling.com
            </Typography>
          </Grid>

          <Grid item sx={{ marginBottom: 2 }}>
            On occasion Mountjoy Enterprises will provide links to pages on
            external sites. The privacy policies of these linked sites are the
            responsibility of the linked site and Mountjoy Enterprises has no
            control or influence over their policies. Please check the policies
            of each site you visit for specific information. Mountjoy
            Enterprises cannot be held liable for damage or misdoings of other
            sites linked or otherwise.
          </Grid>

          <Grid item>
            <Typography variant="h2">In Summary</Typography>
          </Grid>

          <Grid item sx={{ marginBottom: 2 }}>
            Mountjoy Enterprises is committed to protecting your privacy. We use
            the information we collect on the site to make shopping at
            mountjoysparkling.com as simple as possible and to enhance your
            overall shopping experience. We do
          </Grid>

          <Grid item>
            <Typography variant="h2">Your Consent</Typography>
          </Grid>

          <Grid item sx={{ marginBottom: 2 }}>
            By using our Web site, you consent to the collection and use of this
            information by Mountjoy Enterprises. If we decide to change our
            privacy policy, we will post those changes on this page so that you
            are always aware of what information we collect, how we use it, and
            under what circumstances we disclose it.
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Privacy;
