import React, { useState } from "react";
import "../../App.css";
import {
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  Snackbar,
} from "@mui/material";
import Alert from "@awsui/components-react/alert";
import { Helmet } from "react-helmet-async";

// Following two lines are for the actual submission of the email.
import { API } from "aws-amplify";
// Create a record in Dynamo DB.  TODO - We probably don't need this feature.
import { createMessage } from "../../graphql/mutations";
const iFormState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  message: "",
};
const Contact = () => {
  const [formState, setFormState] = useState(iFormState);
  const [showFeedback, setShowFeedback] = useState(null);
  const submissionFeedback = {
    message() {
      return showFeedback
        ? "Message sent.  Thanks!"
        : "An error occurred. Please, try again later or contact our team directly by emailing sales@blackmedicine.com";
    },
    success() {
      setShowFeedback(true);
    },
    error() {
      setShowFeedback(false);
    },
    clear() {
      setShowFeedback(null);
    },
    render() {
      if (showFeedback === false) {
        return (
          <Alert
            onDismiss={this.clear}
            visible={true}
            dismissAriaLabel="Close alert"
            dismissible
            type="error"
            header="Submission Error"
            style={{ textAlign: "center" }}
          >
            {this.message()}
          </Alert>
        );
      }
      if (showFeedback) {
        return (
          <Alert
            onDismiss={this.clear}
            visible={true}
            dismissAriaLabel="Close alert"
            dismissible
            type="success"
            header="Thank you for contacting us!"
            style={{ textAlign: "center" }}
          >
            {this.message()}
          </Alert>
        );
      }
    },
  };
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, message } = formState;

    try {
      await API.graphql({
        query: createMessage,
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phone,
            message,
          },
        },
      });
      setFormState(iFormState);
      submissionFeedback.success();
      <Snackbar message="Successfully submitted"></Snackbar>;
    } catch (e) {
      console.log("error:", e);
      submissionFeedback.error();
      <Snackbar message="Error"></Snackbar>;
    }
  };

  return (
    <div className="App">
      <Helmet>
        <title>Contact Us at Black Medicine</title>
        <meta
          name="description"
          content="For questions about our canning co-packing, brewing, formulation and other services, please send us a note."
        />
      </Helmet>

      <Grid style={{ margin: "2rem 1rem" }}>
        <Card style={{ maxWidth: 450, padding: "20px 5px", margin: "0 auto" }}>
          <CardContent>
            <Typography gutterBottom variant="h5">
              Contact Us
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              gutterBottom
              sx={{ my: 3 }}
            >
              {" "}
              Let's get you into a new can!
            </Typography>
            <form
              onSubmit={handleContactFormSubmit}
              style={{ marginBottom: "0.5rem" }}
            >
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <TextField
                    placeholder="Enter first name"
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.firstName}
                    // Keep track of any changes to this value by updating formState array.
                    onChange={(e) =>
                      setFormState({ ...formState, firstName: e.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField
                    placeholder="Enter last name"
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.lastName}
                    onChange={(e) =>
                      setFormState({ ...formState, lastName: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="email"
                    placeholder="Enter email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.email}
                    onChange={(e) =>
                      setFormState({ ...formState, email: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="tel"
                    placeholder="Enter phone number"
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.phone}
                    onChange={(e) =>
                      setFormState({ ...formState, phone: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Message"
                    multiline
                    rows={4}
                    placeholder="Type your message here"
                    variant="outlined"
                    fullWidth
                    required
                    value={formState.message}
                    onChange={(e) =>
                      setFormState({ ...formState, message: e.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
            {submissionFeedback.render()}
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default Contact;
