import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Terms = () => {
  return (
    <div>
      <Helmet>
        <title>Terms of Use</title>
        <meta name="description" content="Notice of terms of use" />
      </Helmet>
      <Box
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        bgcolor="secondary.main"
        color="text.secondary"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          minHeight="100vh"
        >
          <Grid item>
            <Typography variant="h1">Terms of Use</Typography>
            <br></br>
            <p>
              Welcome and thank you for visiting Mountjoy Enterprises (at our
              website address). The blackmedicine.com website and its associated
              products and services including any related mobile site or
              applications (collectively, "Website") are provided by Mountjoy
              Enterprises. ("Mountjoy Enterprises, Inc."), a California
              corporation. By accessing or using the Website, however accessed,
              you manifest your intent and agree to be bound by this Terms of
              Use Agreement ("Agreement").
            </p>
            <br></br>
            <p>
              This Agreement and the terms contained herein are subject to
              change by Mountjoy Enterprises at any time, in its sole and
              absolute discretion, and without notice. Such changes shall be
              effective immediately upon posting. Therefore, you are instructed
              to review the terms of this Agreement prior to using the Website.
              If you do not agree to the terms and conditions contained within
              this Agreement, you must discontinue your use of the Website
              immediately. Your continued use of the Website after any
              replacement, modification, or amendment of the terms of this
              Agreement will constitute your manifestation of assent to, and
              agreement with, any replacement, modification, or amendment
              herein.
            </p>
            <br></br>
            <p>
              NOTICE OF ARBITRATION. THIS TERMS OF USE AGREEMENT CONTAINS AN
              ARBITRATION PROVISION AND CLASS ACTION WAIVER. EXCEPT AS OTHERWISE
              STATED UNDER THE TERMS OF THIS AGREEMENT, AND IF YOU DO NOT
              OPT-OUT OF ARBITRATION AS SET FORTH BELOW, YOU AGREE THAT ANY AND
              ALL DISPUTES BETWEEN YOU AND MOUNTJOY ENTERPRISES WILL BE RESOLVED
              BY BINDING, INDIVIDUAL ARBITRATION, AND YOU WAIVE YOUR RIGHT TO
              BRING OR RESOLVE ANY DISPUTE AS, OR PARTICIPATE IN, A CLASS,
              CONSOLIDATED, REPRESENTATIVE, COLLECTIVE, OR PRIVATE ATTORNEY
              GENERAL ACTION OR ARBITRATION.
            </p>
            <br></br>
            <Typography variant="h2">ELIGIBILITY TO USE THE WEBSITE</Typography>
            <p>
              The Website is open to use to those who are age eighteen (18) or
              above. By using the Website, you warrant that you are age eighteen
              (18) or above, are of sound mind, and have the capacity to agree
              to and uphold the terms and conditions contained within this
              Agreement. If you use the Website on behalf of a business entity
              or other third party, you warrant that you express actual
              authority to act as an agent of that business entity and third
              party and, as a component of that agency, have the right and
              ability to agree to the terms of this Agreement on behalf of that
              third party or business entity. You further warrant that you are
              not prohibited from entering into this Agreement by the terms of
              any preexisting agreement.
            </p>
            <Typography variant="h2">PRIVACY POLICY</Typography>
            <p>
              Mountjoy Enterprises hereby incorporates its Privacy Policy by
              reference as if fully restated herein. You are instructed to
              review Mountjoy Enterprises's Privacy Policy to understand the
              personal and personally identifiable information that Mountjoy
              Enterprises may collect from you when you use the Website and how
              Mountjoy Enterprises may use that personal or personally
              identifiable information.
            </p>
            <Typography variant="h2">ACCEPTABLE USE OF THE WEBSITE</Typography>
            <p>
              When you use the Website, you agree to use it only for its
              customary and intended purposes and as permitted by the terms of
              this Agreement and any applicable law, regulation, statute, or
              ordinance. Additionally, you agree that you are responsible for
              any breach of your obligations under the terms of this Agreement
              and for any losses suffered by Mountjoy Enterprises for such a
              breach, including, but not limited to, monetary damages, costs,
              and attorneys' fees. You are expressly prohibited from using the
              Website to violate any law, statute, ordinance, regulation, or
              treaty, whether local, state, provincial, national or
              international, or to violate the rights of a third party,
              including, but not limited to, intellectual property rights,
              privacy rights, rights of publicity, or other personal or
              proprietary rights. Additionally, you are expressly prohibited
              from:
            </p>
            <p>
              • Attempting to access the Website other than through a standard
              web browser unless you have been permitted to do so by Mountjoy
              Enterprises through a separate, written agreement; • Accessing or
              attempting to access the Website through automated means; •
              Circumventing the technological protection measures of the
              Website; • Vandalizing the Website; • Using any malware or
              otherwise harmful or malicious code in connection with the
              Website; • Posting or transmitting content intended to collect
              personal or personally identifiable information from users of the
              Website or third parties; • Disrupting or otherwise interfering
              with the Website or its associated servers or networks; •
              Scraping, reproducing, republishing, selling, reselling,
              duplicating, or trading the Website or its content; • Diverting or
              attempting to divert customers of the Website to another website
              or service; • Reverse engineering, decompiling, translating, or
              disassembling the Website or its content; and • Encouraging or
              assisting any other party to do anything in violation of the terms
              of this Agreement. • Mountjoy Enterprises reserves the right to
              modify, amend, or terminate the Website or its associated content
              at any time and without prior notice. Mountjoy Enterprises also
              reserves the right to refuse service or access to the Website to
              any person or business entity at any time and without notice.
            </p>
            <Typography variant="h2">
              LIMITED LICENSE TO USE THE WEBSITE
            </Typography>
            <p>
              You acknowledge and agree that the Website is the property of or
              is licensed by Mountjoy Enterprises and is protected under United
              States and international law, including, but not limited to,
              intellectual property laws and other personal and proprietary
              rights. You acknowledge and agree that your use of the Website is
              limited by the license granted under the terms of this Agreement,
              and you expressly agree that you will not use the Website in any
              manner not expressly authorized under the terms of this Agreement.
              Mountjoy Enterprises reserves all rights not expressly granted
              through this Agreement.
            </p>
            <p>
              Mountjoy Enterprises provides you with a limited, non-exclusive,
              non-sublicensable, non-assignable, revocable, and royalty free
              license to use the Website for its customary and intended
              purposes. You are expressly prohibited from reproducing, preparing
              derivative works of, distributing copies of, publicly performing,
              and publicly displaying the Website.
            </p>
            <Typography variant="h2">IDEA SUBMISSION POLICY</Typography>
            <p>
              Mountjoy Enterprises may provide you with the ability to submit
              ideas or feedback to Mountjoy Enterprises through the Website. If
              you submit ideas or feedback to Mountjoy Enterprises, you agree
              that any ideas or feedback that you submit to Mountjoy Enterprises
              will automatically become the property of Mountjoy Enterprises and
              that you will not be compensated for the submission, use, or
              implementation of the idea or feeback that you submitted to
              Mountjoy Enterprises. You understand and agree that Mountjoy
              Enterprises may use or redistribute any ideas or feedback that you
              submit to Mountjoy Enterprises for any purpose and in any way and
              that Mountjoy Enterprises has no obligation to keep any ideas or
              feedback submitted to Mountjoy Enterprises confidential.
            </p>
            <Typography variant="h2">USER ACCOUNT</Typography>
            <p>
              Mountjoy Enterprises may provide you with the ability to register
              a user account ("User Account"), which may provide access to
              additional areas of the Website, including, but not limited to,
              those areas or functions of the Website that are only open to
              wholesale accounts. Your User Account is protected by a username
              and password. You recognize that you are solely responsible for
              maintaining the security and confidentiality of your username and
              password and that you are responsible for any unauthorized access
              to your User Account. In the event your User Account is accessed
              without your authorization, you agree to notify Mountjoy
              Enterprises immediately. Mountjoy Enterprises reserves the right
              to restrict access to, suspend, disable, or delete your User
              Account at any time, in its sole discretion, and without prior
              warning. By creating a User Account, Mountjoy Enterprises may
              contact you by any available means, including, but not limited to,
              by email.
            </p>

            <Typography variant="h2">PROPRIETARY RIGHTS</Typography>
            <p>
              You understand and agree that the Website, including, but not
              limited to, its source code, data, selection and arrangement,
              executable code, structure, and organization, contains the
              valuable trade secrets and intellectual property of Mountjoy
              Enterprises. Under the terms of this Agreement, you do not acquire
              any ownership rights to the Website or the data or content
              contained therein. You acquire only a limited license to use the
              Website subject to the terms of this Agreement. All other rights
              are reserved by Mountjoy Enterprises.
            </p>
            <Typography variant="h2">TRADEMARKS</Typography>
            <p>
              You acknowledge and agree that any and all trademarks, trade
              names, design marks, or logos displayed on or through the Website
              by Mountjoy Enterprises are common law or registered trademarks
              owned by or licensed to Mountjoy Enterprises. You are expressly
              prohibited from using the trademarks of Mountjoy Enterprises to
              cause confusion in, to cause mistake in, or to deceive consumers,
              or from falsely designating the origin of, the source of, or the
              sponsorship of your goods or services. You are further prohibited
              from using the trademarks of Mountjoy Enterprises in domain names,
              in keyword advertisements, to trigger keyword advertisements, or
              in meta tags. All other trademarks, trade names, design marks, or
              logos are the property of their respective owners.
            </p>

            <Typography variant="h2">TERM AND TERMINATION</Typography>
            <p>
              The term of this Agreement will begin upon your first accessing of
              the Website and will continue until the earlier of the following:
              (i) Mountjoy Enterprises terminates your access to the Website; or
              (ii) you cease using the Website and terminate your Account.
              Mountjoy Enterprises reserves the right to terminate the Website
              or your access to the Website in its sole and absolute discretion
              and without prior notice for any reason whatsoever, including
              without limitation your breach of the Agreement or any of its
              terms.
            </p>
            <Typography variant="h2">
              DISCLAIMER REGARDING ACCURACY OF VENDOR INFORMATION
            </Typography>
            <p>
              Product specifications and other information listed on the Website
              have either been provided by third party product vendors or
              collected from publicly available sources. While Mountjoy
              Enterprises makes reasonable efforts to ensure that the
              information on this Website is accurate, we can make no
              representations or warranties as to the accuracy or reliability of
              any information provided on the Website. Mountjoy Enterprises
              makes no warranties or representations whatsoever with regard to
              any product provided or offered by any third party vendor, and you
              acknowledge that any reliance on representations and warranties
              provided by any third party vendor shall be at your own risk.
            </p>
            <Typography variant="h2">
              DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
            </Typography>
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT THE WEBSITE IS PROVIDED ON AN
              "AS-IS" BASIS AND WITHOUT WARRANTY OF ANY KIND, INCLUDING, BUT NOT
              LIMITED TO, WARRANTIES OF TITLE, MERCHANTABILITY, ACCURACY,
              FITNESS FOR A PARTICULAR PURPOSE, SECURITY, AND NON-INFRINGEMENT.
              WHEREVER PERMITTED BY LAW, YOU ACKNOWLEDGE THAT MOUNTJOY
              ENTERPRISES WILL NOT BE HELD RESPONSIBLE OR LIABLE FOR ANY CLAIMS,
              DAMAGES, JUDGMENTS, CHARGES, OR FEES ARISING OUT OF OR RELATED TO
              YOUR USE OF OR ACCESS TO THE WEBSITE OR TRANSACTIONS THAT OCCUR
              THROUGH THE WEBSITE, INCLUDING, BUT NOT LIMITED TO, COMPENSATORY
              DAMAGES, CONSEQUENTIAL DAMAGES, SPECIAL DAMAGES, INCIDENTAL
              DAMAGES, PUNITIVE DAMAGES, EXEMPLARY DAMAGES, COSTS, AND
              ATTORNEYS' FEES, DAMAGES ARISING OUT OF ERRORS OR OMISSIONS, AND
              DAMAGES ARISING OUT OF THE UNAVAILABILITY OF THE WEBSITE OR
              DOWNTIME. YOU ACKNOWLEDGE THAT YOUR USE OF THE WEBSITE IS AT YOUR
              SOLE RISK AND THAT MOUNTJOY ENTERPRISES'S LIABILITY IS LIMITED TO
              THE AMOUNT THAT YOU PAID TO MOUNTJOY ENTERPRISES OR $1,000,
              WHICHEVER IS LESS. MOUNTJOY ENTERPRISES EXPRESSLY DISCLAIMS ANY
              RESPONSIBILITY FOR ANY DAMAGE, INJURY, HARM, COST, EXPENSE, OR
              LIABILITY ARISING OUT OF OR RELATED TO YOUR USE OR MISUSE OF ITEMS
              PURCHASED THROUGH THE WEBSITE. ITEMS PURCHASED THROUGH THE WEBSITE
              ARE PROVIDED WITHOUT EXPRESS, IMPLIED, OR STATUTORY WARRANTIES
              FROM MOUNTJOY ENTERPRISES, INCLUDING, BUT NOT LIMITED TO,
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PUPOSE,
              TITLE, ACCURACY, NON-INFRINGEMENT, OR QUALITY. SOME JURISDICTIONS
              DO NOT ALLOW AN EXCLUSION OF IMPLIED WARRANTIES. IF YOU ARE
              LOCATED IN SUCH A JURISDICTION, YOU ARE ADVISED TO SEEK LEGAL
              ADVICE TO DETERMINE IF THIS EXCLUSION APPLIES TO YOU. MOUNTJOY
              ENTERPRISES WILL NOT BE LIABLE TO YOU UNDER ANY LEGAL THEORY,
              WHETHER IN WARRANTY, CONTRACT, STRICT LIABILITY, TORT, PERSONAL
              INJURY, OR NEGLIGENCE, FOR ANY DAMAGES, CLAIMS, INJURIES,
              JUDGMENTS, COSTS, OR LIABILITIES OF ANY KIND ARISING OUT OF OR
              RELATED TO YOUR USE OR MISUSE ITEMS PURCHASED THROUGH THE WEBSITE,
              INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, DAMAGE TO
              PROPERTY, DEATH, LOSS OF BUSINESS, LOSS OF INCOME, SPECIAL
              DAMAGES, INCIDENTAL DAMAGES, CONSEQUENTIAL DAMAGES, PUNATIVE
              DAMAGES, OR EXEMPLARY DAMAGES, WHETHER FORESEEABLE OR
              UNFORESEEABLE. YOU ACKNOWLEDGE THAT YOUR USE OF ITEMS PURCHASED
              THROUGH THE WEBSITE IS AT YOUR SOLE RISK AND THAT MOUNTJOY
              ENTERPRISES'S LIABILITY IS LIMITED TO THE AMOUNT THAT YOU PAID TO
              MOUNTJOY ENTERPRISES OR $1,000, WHICHEVER IS LESS. All information
              contained on the Website, including information relating to
              medical and health conditions, products, and treatments, is for
              informational purposes only. Such information is often summarized
              or presented in aggregate form and is not meant to be a substitute
              for the advice provided by your own physician or other medical
              professionals or any information contained on or in any product
              packaging or labels. MOUNTJOY ENTERPRISES is not responsible for
              any statements or claims that various manufacturers make about
              their products and cannot be held responsible for typographical
              errors or product formulation changes. THE INFORMATION CONTAINED
              ON THIS SITE SHOULD NOT BE USED FOR DIAGNOSING A HEALTH PROBLEM OR
              PRESCRIBING A MEDICATION. YOU SHOULD CAREFULLY CONSULT ALL
              INFORMATION PROVIDED BY THE MANUFACTURERS OF THE PRODUCTS ON OR IN
              THE PRODUCT PACKAGING AND LABELS BEFORE USING ANY PRODUCT
              PURCHASED ON THIS SITE. YOU SHOULD ALWAYS CONSULT YOUR OWN
              PHYSICIAN AND MEDICAL ADVISORS. INFORMATION AND STATEMENTS
              REGARDING DIETARY SUPPLEMENTS HAVE NOT BEEN EVALUATED BY THE FOOD
              AND DRUG ADMINISTRATION AND ARE NOT INTENDED TO DIAGNOSE, TREAT,
              CURE, OR PREVENT ANY DISEASE OR ILLNESS.
            </p>
            <Typography variant="h2">INDEMNIFICATION</Typography>
            <p>
              You agree to indemnify, defend, and hold harmless Mountjoy
              Enterprises, its officers, shareholders, directors, employees,
              subsidiaries, affiliates, and representatives, from and against
              any and all losses, including, but not limited to, costs and
              attorneys' fees, arising out of or related to (i) your use of the
              Website, (ii) your purchase of any item through the Website, (iii)
              your violation of any term or condition of this Agreement; (iv)
              your violation of the rights of third parties, including, but not
              limited to, intellectual property rights or other personal or
              proprietary rights; and (v) your violation of any law, statute,
              ordinance, regulation, or treaty, whether local, state,
              provincial, national, or international. Your obligation to defend
              Mountjoy Enterprises will not provide you with the ability to
              control Mountjoy Enterprises' defense, and Mountjoy Enterprises
              reserves the right to control its defense, including its choice of
              counsel and whether to litigate or settle a claim subject to
              indemnification.
            </p>
            <Typography variant="h2">
              CHOICE OF LAW AND STIPULATION TO JURISDICTION
            </Typography>
            <p>
              You and Mountjoy Enterprises agree that any dispute arising out of
              or related to this Agreement or your use of the Website,
              including, but not limited to, your purchase or use of products
              through the Website, will be governed by the laws of the State of
              California, without regard to its conflict of laws rules.
              Specifically, the validity, interpretation, and performance of
              this Agreement will not be governed by the United Nations
              Convention on the International Sale of Goods. Except for claims
              for injunctive relief by either party, you and Mountjoy
              Enterprises agree that any dispute or controversy arising out of,
              in relation to, or in connection with this Agreement or your use
              of the Website or products purchased through the Website,
              including, without limitation, any and all disputes, claims
              (whether in tort, contract, statutory, or otherwise), or
              disagreements concerning the existence, breach, interpretation,
              application, or termination of this Agreement, will be resolved by
              final and binding arbitration pursuant to the Federal Arbitration
              Act in Santa Cruz County, California or, at the option of the
              party seeking relief, by telephone, online, or via written
              submissions alone, and be administered by the American Arbitration
              Association ("AAA") under the then in force Commercial Arbitration
              Rules by one arbitrator appointed in accordance with such rules.
              Such arbitration will be independent and impartial. If the parties
              fail to agree on the arbitrator within twenty (20) calendar days
              after the initiation of the arbitration hereunder, AAA will
              appoint the arbitrator. This arbitration will be conducted in the
              English language. The decision of the arbitrator will be final and
              binding on the parties and judgment on any award(s) rendered by
              the arbitrator may be entered in any court having jurisdiction
              thereof. Nothing in this section will prevent either party from
              seeking immediate injunctive relief from any court of competent
              jurisdiction, and any such request shall not be deemed
              incompatible with the agreement to arbitrate or a waiver of the
              right to arbitrate. The parties undertake to keep confidential all
              awards in their arbitration, together with all confidential
              information, all materials in the proceedings created for the
              purpose of the arbitration and all other documents produced by the
              other party in the proceedings and not otherwise in the public
              domain, save and to the extent that disclosure may be required of
              a party by legal duty, to protect or pursue a legal right or to
              enforce or challenge an award in legal proceedings before a court
              or other judicial authority. The arbitrator shall award all fees
              and expenses, including reasonable attorney's fees, to the
              prevailing party. Any judgment rendered by the arbitrator may be
              entered in any court of competent jurisdiction. ANY DISPUTE
              RESOLUTION PROCEEDING ARISING OUT OF OR RELATED TO THIS AGREEMENT
              OR THE PURCHASE OR USE OF ITEMS FROM OR THROUGH THE WEBSITE,
              WHETHER IN ARBITRATION OR OTHERWISE, SHALL BE CONDUCTED ONLY ON AN
              INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED, OR
              REPRESENTATIVE ACTION, AND YOU AND Mountjoy Enterprises EXPRESSLY
              AGREE THAT CLASS ACTION AND REPRESENTATIVE ACTION PROCEDURES SHALL
              NOT BE ASSERTED IN OR APPLY TO ANY ARBITRATION PURSUANT TO THESE
              TERMS. Any claims must be brought within one year of each
              applicable invoice or will otherwise be barred.
            </p>
            <Typography variant="h2">FORCE MAJEURE</Typography>
            <p>
              Mountjoy Enterprises will not be responsible for any delay or
              failure in performance of the Website or its associated products
              arising out of any cause beyond Mountjoy Enterprises' control,
              such as acts of God, war, riots, fire, terrorist attacks,
              pandemics, power outages, severe weather, shipping delays,
              accidents, or other matters beyond the control of Mountjoy
              Enterprises.
            </p>
            <Typography variant="h2">SURVIVABILITY</Typography>
            <p>
              The representations, warranties, duties, and covenants made by you
              under this Agreement will survive the termination of this
              Agreement, your Account, or the Website, including, but not
              limited to, your duty to indemnify and defend Mountjoy
              Enterprises.
            </p>
            <Typography variant="h2">INTERPRETATION</Typography>
            <p>
              This Agreement will be deemed to have been drafted by both
              parties, and the terms and conditions of this Agreement will not
              be interpreted against its drafter.
            </p>
            <Typography variant="h2">ASSIGNMENT</Typography>
            <p>
              You are expressly prohibited from assigning your rights and duties
              under this Agreement. Mountjoy Enterprises reserves the right to
              assign its rights and duties under this Agreement, including in a
              sale of Mountjoy Enterprises or its Website.
            </p>
            <Typography variant="h2">WAIVER AND INTEGRATION</Typography>
            <p>
              No term or condition of this Agreement or breach of this Agreement
              will be deemed to have been waived or consented to unless said
              waiver is writing and signed by the party to be charged. This
              Agreement is the entire agreement between the parties and
              supersedes all previous agreements or representations between the
              parties.
            </p>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Terms;
