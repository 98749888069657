// 404 error catch all.

import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const NotFound = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Grid item>
        <Box>
          <Typography variant="h4">Page does not exist</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotFound;
