import React from "react";
import { Typography, Box, Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Block } from "@mui/icons-material";

const Craft = () => {
  return (

    <Container>
      <Helmet>
        <title>Craft Co-Packing</title>
        <meta
          name="description"
          content="Craft quality canning of your coffee, tea or carbonated beverage brand."
        />
      </Helmet>

      <Box
        sx={{ maxWidth: "lg" }}
        px={{ xs: 3, sm: 10 }}
        py={{ xs: 5, sm: 10 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid container>
          <Grid item xs={12} sx={{ my: 3 }}>
            <Typography variant="h1" align="center">
              Craft Co-Packing
            </Typography>
            <Typography pb={2}><br></br>We specialize in coffee drinks — from simple black to complex lattes and mochas.  But our capabilities extend to sparkling water, sparkling teas, sodas and innovative liquid concoctions of all types.</Typography>
            <Typography pb={2}>Regardless of your needs or beverage product, or your stage in growth, we bring the same craft level of care to each project. </Typography>


          </Grid>



          <Grid item sx={{ marginBottom: 2 }}>

            <Typography variant="h4">Nitrogen Extraction</Typography>
            <Typography pb={2}>
              After brewing, we use compressed nitrogen to separate your concentrate from the grounds.  This “nitro” step increases yield and limits dissolved oxygen.
            </Typography>
            <Typography variant="h4">Grind to Brew</Typography>
            <Typography pb={2}>We process beans in small batches of about 40 lbs each.  Each batch is weighed, ground and brewed immediately, assuring freshness and consistency.</Typography>
            <Typography variant="h4">Brewing</Typography>
            <Typography pb={2}>Brewing of coffees and teas is done in house, using our proprietary method. For teas, we can work from a powder (Guayusa, for example) or leaf.  Our nitro brewing method is a big reason growing coffee companies prefer us to package their brand.</Typography>
            <Typography variant="h4">TDS Testing</Typography>
            <Typography pb={2}>Different beans and different roasts yield concentrates of different TDS (Total Dissolved Solids).  We test for TDS at several points in the production process, first after extraction, then again once the concentrates are batched for canning and finally during a proof of your recipe, before the final mixing.  After batching, we use our measurements to revisit your recipe and adjust the concentrate percentage as appropriate, assuring consistency in your product.</Typography>
            <Typography variant="h4">Brewing</Typography>
            <Typography pb={2}>Brewing of coffees and teas is done in house, using our proprietary extraction method. Our brewing technique is a principal reason growing coffee companies choose us to formulate and package their brand.</Typography>
            <Typography variant="h4">Flavoring</Typography>
            <Typography pb={2}>When choosing a flavor for your soda or sparkling tea, we offer our proven-successful flavorings as options.  But you may supply liquid flavorings from your own flavor house as well.</Typography>
            <Typography variant="h4">White Label</Typography>
            <Typography pb={2}>Save time and reduce risk by choosing from our list of demonstrated successful flavors for sparkling waters, sparkling teas and mocktails.  Make them yours with your own labeling, and you can leapfrog focus-group testing and go right to selling.</Typography>
            <Typography variant="h4">Weddings / Special Events / Corporate Gift</Typography>
            <Typography pb={2}>Whether your guest list is ten or ten thousand, let us help you provide a unique and memorable experience with designs that fit your event theme and brand.  All of our coffee drinks, sparkling teas, sparkling waters and mocktails are available for private labeling.</Typography>
            <Typography variant="h4">Shrink Sleeve or Pressure Sensitive Label - How to Choose</Typography>
            <Typography pb={2}>Shrink sleeves provide fullest coverage, whereas pressure-sensitive labels offer faster turnaround, lower minimums and make it easy to combine multiple designs into a single order, saving you money.</Typography>
            <Typography pb={2}>Craft beer is commonly stickered rather than sleeved.  As a result, consumers are familiar with the look of labeled cans, and may even have positive associations with the finish, unconsciously equating the labeled look with small batch,  artisan-quality products.</Typography>
            <Typography pb={2}>Pressure sensitive lavels also offer a choice of substrates.  From white BOPP to metallic, to paper.  Metallic label design, which can yield elegant and eye catching cans, is a house specialty.</Typography>
            <Typography variant="h4">What About Digital Printing onto Cans?</Typography>
            <Typography pb={2}>When you have a single SKU that you're selling in truckload volume, then printing directly onto cans make sense.  Lower quantities -- for example, a pallet of 8,000 cans -- can be digitally printed as well, though the cost saved over labeling or sleeving may not justify the investment.</Typography>
            <Typography variant="h4">Can Size Options</Typography>
            <Typography pb={2}>We fill primarily using a 330 ml sleek, as this size works well for coffee, tea and specialty drinks, which can be filled to 9.5 or 10 oz in the 330 ml (11 oz) can.  It's a versatile size, with excellent shelf stature.</Typography>
            <Typography pb={2}>The U.S. standard of course is the 12 oz sleek, which we also handle.  If you're looking for an 8 oz fill, then you would choose a 250 ml sleek, which we can also run.  Other formats, such as 16 oz and imperial pint are available with advance notice and special setup.</Typography>
            <Typography variant="h4">Case Pack</Typography>
            <Typography pb={2}>Our coffee customers primarily use a 12-pack box for their cans.  We offer stock boxes, to which we apply your brand label.  You may also have boxes custom-printed by the pallet and sent directly to us. </Typography>
            <Typography pb={2}>Case trays, 24 cans per tray, are also an option.  We use Polyolefin rather than PVC for a crisp, crystal-clear finish.  </Typography>
            <Typography pb={2}>Our distributors have complimented us on our shrink wrap material.  The things some people care about!</Typography>
            <Typography variant="h4">Pasteurization</Typography>
            <Typography pb={2}>Non-carbonated drinks containing milk or sugar may require pasteurization.  Our pasteurization chamber handles 80 cases per cycle, heating your beverage briefly to 70 degrees Celsius, then bringing it quickly back down to fridge temperature, 5 degrees C.  </Typography>
            <Typography variant="h4">Cold Chain</Typography>
            <Typography pb={2}>If your carrier cannot pick up your refrigerated product right away, or if you’d like to split your order into a now and later deliveries, we will keep your product in our cold storage for up to 20 days.  Prices for longer-term storage available on request. </Typography>
            <Typography variant="h4">Do I Need a Formulator?</Typography>
            <Typography pb={2}>With our knowledge of formulation, pasteurization, carbonation and preservatives, we can likely help you get to a process-authority ready recipe, ready for shelf-life testing.</Typography>
            <Typography pb={2}>Carbonated beverages are easiest to manage, shelf-life wise, due to carbon-dioxide’s natural preservative qualities.</Typography>
            <Typography pb={2}>Next easiest are still beverages containing neither sugar nor dairy, such as black coffee or tea.  In our experience, such drinks, if kept cold and ph-adjusted can have a shelf life of 5-6 months, without pasteurization.</Typography>
            <Typography pb={2}>Lastly, if your recipe includes fresh ingredients, dairy or sugar, likely your beverage will require pasteurization to satisfy the process authority letter, and will need to be kept cold for the duration of the shelf life.</Typography>
            <Typography variant="h4">What About Retort?</Typography>
            <Typography pb={2}>Heating a beverage to a high temperature, in its can, in a chamber under pressure is called “retort”.  Retort yields a shelf-stable product, but may require compromising your flavor expectations.  In the case of coffee drinks, for example, we've heard that retort has a negative impact on taste, which may be why our customers, all of whom are higher-end, choose to stay away from the process.  We do not offer retort.</Typography>
            <Typography variant="h4">Any Size Run Okay</Typography>
            <Typography pb={2}>We pride ourselves in being able to accommodate brands at any point in their growth.  For brand new products, we offer startup packages to get you quickly up and running with a minimum of investment and in the shortest possible time.  If you're working on developing a recipe, we offer lab time sessions during which a staff person will work with you to quickly arrive at a formulation you like.</Typography>
            <Typography pb={2}>Whatever your size or ambitions, we promise to bring all our experience to bear on making your product a success. </Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Craft;
