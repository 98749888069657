import React from "react";
import { Typography, Box, Container, Grid, FormGroup } from "@mui/material";
import { NavLink } from "react-router-dom";

import logo from "../../static/images/logo.png";
import { Center } from "@chakra-ui/react";

const ResponsiveFooter = () => {
  return (
    <div>
      <Box px={{ xs: 3, sm: 10 }} py={{ xs: 5, sm: 3 }} bgcolor="primary.main">
        <Container>
          <Grid container spacing={3}>
            <Grid container>
              <Grid item xs style={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Box borderBottom={1}>
                    <Typography variant="h6">Learn More</Typography>
                  </Box>
                  <Box>
                    <NavLink underline="hover" to="/contact">
                      Contact Us
                    </NavLink>
                  </Box>
                  <Box>
                    <NavLink underline="hover" to="/about">
                      About Us
                    </NavLink>
                  </Box>
                  <Box>
                    <NavLink underline="hover" to="/privacy">
                      Privacy Policy
                    </NavLink>
                  </Box>
                  <Box>
                    <NavLink underline="hover" to="/terms">
                      Terms of Use
                    </NavLink>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <img
                  className="rounded-md"
                  width="200px"
                  src={logo}
                  alt="Footer logo"
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default ResponsiveFooter;
