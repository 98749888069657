// Display copyright notice.  Place this component in footer.

import React from "react";
import { Typography, Box, Container, Grid } from "@mui/material";

const Copyright = () => {
  return (
    <Box bgcolor="primary.dark">
      <Container>
        <Grid container>
          <Grid item xs={12} className="text-center">
            <Typography color="white" variant="body2">
              &copy; {new Date().getFullYear()} Mountjoy Enterprises Inc.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Copyright;
